<script setup lang="ts">
const useAppList = useAppListStore()
const { openApp, delApp } = useAppList
const { menuList, currentApp } = storeToRefs(useAppList)
const activeKey = computed(() => currentApp.value?.id || '')
function onEdit(key: any) {
  delApp(key)
}
function tabClick(key: any) {
  openApp(key)
}
</script>

<template>
  <div class="header-tabs flex items-center">
    <a-tabs :active-key="activeKey" hide-add type="editable-card" @edit="onEdit" @tab-click="tabClick">
      <a-tab-pane v-for="pane in menuList" :key="pane.id" closable>
        <template #tab>
          <div flex items-center>
            <img v-if="pane.icon" :src="pane.icon" style="width: 20px;height: 20px;margin-right: 5px;">
            {{ pane.name }}
          </div>
        </template>
      </a-tab-pane>
    </a-tabs>

    <!-- <a-space ></a-space> -->
    <!-- TODO -->
  </div>
</template>

<style lang="scss" scoped>
.header-tabs {
  padding: 0 12px;
  height: 100%;

  :deep(.ant-tabs) {

    width: 100%;

    .ant-tabs-nav {
      margin: 0 !important;
    }

    .ant-tabs-content-holder {
      display: none;
    }

    .ant-tabs {
      width: 100%;
    }

    .ant-tabs-tab {
      padding: 4px 8px !important;
      background: transparent !important;
      border: 1px solid transparent;
      border-radius: 4px !important;
      margin-left: 8px !important;
      height: 32px;
      position: relative;
      color: rgba(255, 255, 255, 0.8);
      border: 1px solid rgba(255, 255, 255, 0.5);

      &:after {
        position: absolute;
        content: '';
        width: 1px;
        height: 8px;
        background-color: rgba(255, 255, 255, 0.2);
        right: -5px;
        top: 50%;
        transform: translateY(-50%);
      }

      &.ant-tabs-tab-active {
        border-color: #1668dc;
      }

      &:hover {
        background: rgba(255, 255, 255, 0.1) !important;

        .ant-tabs-tab-remove {
          opacity: 1;
        }
      }

      .ant-tabs-tab-remove {
        opacity: 0;
        margin-top: 2px;
      }
    }
  }
}
</style>
