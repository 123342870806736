<script setup lang="ts">
import { CopyOutlined, GlobalOutlined, LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons-vue'
import type { Rule } from 'ant-design-vue/es/form'
import { updatePasswordApi } from '@/api/user'
import { getLicenseInfoApi, updateLicenseApi } from '@/api/license'
import emitter from '@/utils/mitt'

const appListStore = useAppListStore()
const message = useMessage()
const userStore = useUserStore()
const multiTabStore = useMultiTab()
const layoutMenuStore = useLayoutMenu()
const router = useRouter()
const { avatar, nickname, userId } = storeToRefs(userStore)
const passwordOpen = ref(false)
const licenseModalVisible = ref(false)
const passwordFormRef = ref()
const rules: Record<string, Rule[]> = {
  password: [{ required: true, validator: validatePass, trigger: 'change' }],
  confirmPassword: [{ validator: validatePass2, trigger: 'change' }],
}

const licenseRules: Record<string, Rule[]> = {
  license: [
    { required: true, validator(_rule: Rule, value: string) {
      if (value === '' || value === undefined) {
        return Promise.reject(new Error('请输入授权码'))
      }

      const split = value.trim().split('\n')
      if (split.length !== 2) {
        return Promise.reject(new Error('授权码格式错误：应为两行'))
      }

      return Promise.resolve()
    }, trigger: 'change' },
  ],
}

const passwordFormState = reactive({
  password: '',
  confirmPassword: '',
})

const licenseFormRef = ref()
const licenseFormState = ref({
  licenseCode: [] as any,
  baseboardSerialNumber: '',
  processorId: '',
  verified: false,
  expireDate: '',
  license: '',
})

async function handleClick({ key }: any) {
  if (key === 'logout') {
    const hide = message.loading('退出登录...', 0)
    try {
      emitter.emit('onLogout')
      await userStore.logout()
    }
    finally {
      hide()
      message.success('退出登录成功', 3)
      router.push({
        path: '/login',
      }).then(() => {
        multiTabStore.clear()
        layoutMenuStore.clear()
      })
    }
  }

  if (key === 'password') {
    passwordOpen.value = true
  }

  if (key === 'license') {
    licenseModalVisible.value = true
  }

  if (key === 'config') {
    appListStore.openApp(-1)
  }
}

function submitPassword() {
  passwordFormRef.value.validate().then(async () => {
    const { password, confirmPassword } = passwordFormState
    const isSuccess = await updatePasswordApi({ password, confirmPassword, userId: userId.value })
    if (isSuccess === true)
      passwordOpen.value = false
    message.success('密码修改成功')
  })
}

async function validatePass(_rule: Rule, value: string) {
  if (value === '') {
    return Promise.reject(new Error('请输入密码'))
  }
  else {
    if (passwordFormState.confirmPassword !== '') {
      passwordFormRef.value.validateFields('confirmPassword')
    }
    return Promise.resolve()
  }
}

async function validatePass2(_rule: Rule, value: string) {
  if (value === '') {
    return Promise.reject(new Error('请再次输入密码'))
  }
  else if (value !== passwordFormState.password) {
    return Promise.reject(new Error('两次输入不一致'))
  }
  else {
    return Promise.resolve()
  }
}

watch(licenseModalVisible, async (newVisible) => {
  if (newVisible === true) {
    await refreshLicense()
  }
}, { immediate: true })

async function refreshLicense() {
  licenseFormState.value = await getLicenseInfoApi()
  licenseFormState.value.licenseCode = [licenseFormState.value.baseboardSerialNumber, licenseFormState.value.processorId]
  licenseFormState.value.license = ''
}

function onLicense() {
  licenseFormRef.value.validate().then(async () => {
    const param = licenseFormState.value.license.trim().split('\n')
    await updateLicenseApi(param)
    await refreshLicense()
    if (licenseFormState.value.verified) {
      message.success('授权成功')
    }
    else {
      message.warn('授权失败')
    }
  })
}

async function onCopy() {
  const data = `["${licenseFormState.value.baseboardSerialNumber}", "${licenseFormState.value.processorId}"]`
  await navigator.clipboard.writeText(data)
  message.success('已经复制到剪切板')
}
</script>

<template>
  <a-dropdown>
    <span hover="bg-[var(--hover-color)]" flex items-center h-48px px-12px cursor-pointer class="transition-all-300">
      <a-avatar :src="avatar" mr-8px size="small" />
      <span class="anticon">{{ nickname }}</span>
    </span>
    <template #overlay>
      <a-menu @click="handleClick">
        <a-menu-item key="license">
          <template #icon>
            <GlobalOutlined />
          </template>
          授权
        </a-menu-item>
        <a-menu-item key="config">
          <template #icon>
            <SettingOutlined />
          </template>
          系统配置
        </a-menu-item>
        <a-menu-item key="password">
          <template #icon>
            <UserOutlined />
          </template>
          修改密码
        </a-menu-item>
        <a-menu-divider />
        <a-menu-item key="logout">
          <template #icon>
            <LogoutOutlined />
          </template>
          退出登录
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>

  <a-modal v-model:open="passwordOpen" :centered="true" title="修改密码" @ok="submitPassword">
    <a-form ref="passwordFormRef" :model="passwordFormState" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" :rules="rules">
      <a-form-item label="新密码" name="password">
        <a-input v-model:value="passwordFormState.password" type="password" />
      </a-form-item>
      <a-form-item label="确认新密码" name="confirmPassword">
        <a-input v-model:value="passwordFormState.confirmPassword" type="password" />
      </a-form-item>
    </a-form>
  </a-modal>

  <a-modal v-model:open="licenseModalVisible" :centered="true" width="30%" title="授权" ok-text="授权" @ok="onLicense">
    <a-form ref="licenseFormRef" :model="licenseFormState" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }" :rules="licenseRules">
      <a-form-item label="授权申请码" name="licenseCode">
        <a-space>
          {{ licenseFormState.licenseCode }}

          <a-tooltip>
            <template #title>
              复制授权申请码
            </template>
            <a-button type="link" @click="onCopy">
              <CopyOutlined />
            </a-button>
          </a-tooltip>
        </a-space>
      </a-form-item>
      <a-form-item label="授权状态" name="verified">
        {{ licenseFormState.verified ? '有效' : '无效' }}
      </a-form-item>
      <a-form-item label="授权过期日" name="expireDate">
        {{ licenseFormState.expireDate }}
      </a-form-item>
      <a-form-item label="授权码" name="license" required>
        <a-textarea v-model:value="licenseFormState.license" placeholder="请输入授权码" :rows="4" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
