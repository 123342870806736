<script setup lang="ts">
import { useLayoutState } from '../../basic-layout/context'

const { isMobile } = useLayoutState()

const cacheConfigStore = useWorkBenchCacheConfigStore()

const { project } = storeToRefs(cacheConfigStore)
// const cls = computed(() => ({
//   'ant-pro-global-header-logo': layout.value === 'mix' || isMobile.value,
//   'ant-pro-top-nav-header-logo': layout.value === 'top' && !isMobile.value,
// }))
const router = useRouter()
</script>

<template>
  <div class="ant-pro-global-header-logo" w-184px flex-none>
    <a c-primary @click="router.push('/workbench')">
      <img :src="project?.icon">
      <h1 v-if="!isMobile">{{ project?.name ?? "平台名称" }}</h1>
    </a>
  </div>
</template>
